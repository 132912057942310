import React from "react";

const IceCream = ({ color }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10552_35249)">
        <path d="M4.00763 9.92893C3.95326 9.39514 4.19796 4.16366 4.46986 3.38958C4.74176 2.6155 5.65088 2.03875 6.67225 2.00163C7.75686 1.96222 8.82027 2.64219 9.06496 3.38958C9.30965 4.13697 9.5 9.28997 9.5 9.92893C9.5 10.5679 8.75824 10.5713 8.40477 10.4094C8.0513 10.2474 7.16167 10.4094 7.16167 10.4094V12.7048C7.16167 12.7048 7.18887 12.9718 6.83539 12.9984C6.4819 13.0251 6.37316 12.7048 6.37316 12.7048V10.4094H6.14801C6.14801 10.4094 6.148 10.9415 5.82173 10.8348C5.49545 10.7281 5.77498 10.4094 5.33994 10.4094C4.9049 10.4094 5.76413 12.1608 5.01366 12.3045C4.21217 12.4578 4.66019 10.5962 4.55143 10.3026C4.44267 10.009 4.06201 10.4627 4.00763 9.92893Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_10552_35249">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
export default IceCream;
